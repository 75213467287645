<template>
    <div class="form">
        <el-form ref="form" :model="form" label-width="110px" size="small">
            <el-form-item label="版本">
                <el-input v-model="form.version_name" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="名称">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="应用页面地址">
                <el-input v-model="form.unique_name">
                    <template slot="prepend">{{localhost}}</template>
                </el-input>
            </el-form-item>
            <el-form-item label="状态">
                <el-radio-group v-model="form.status">
                    <el-radio :label="0">下线</el-radio>
                    <el-radio :label="1">上线</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="强制更新">
                <el-radio-group v-model="form.force_update">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="版本更新说明">
                <WangEditor v-model="form.explain"></WangEditor>
            </el-form-item>
            <el-form-item label="包名">
                <el-input v-model="form.pkg" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="地区">
                <el-checkbox-group v-model="form.extra.area">
                    <el-checkbox :label="item" v-for="item in areaList" :key="item"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { getArea } from '@/api/appAPI.js';
import WangEditor from '@/common/wangEditor';

export default {
    components: {
        WangEditor
    },
    props: {
        form: {
            type: Object,
            default(){
                return {};
            }
        }
    },
    data(){
        return {
            areaList: [],
        }
    },
    computed: {
        localhost(){
            return location.origin + '/';
        }
    },
    methods: {
        
    },
    created(){
        getArea().then(res => {
            this.areaList = res;
        })
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.form{
    z-index: -1;
}
</style>