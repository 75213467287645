<template>
    <el-dialog :visible="open" @update:visible="cancel" width="1100px" :close-on-click-modal="false">
        <div class="edit-page">
            <EditForm :form="form"></EditForm>
            <div class="btn-group">
                <el-button size="small" plain @click="cancel">取&nbsp;&nbsp;消</el-button>
                <el-button size="small" type="primary" @click="commit">保&nbsp;&nbsp;存</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { saveApp } from '@/api/appAPI.js';
import EditForm from './EditForm.vue';

export default {
    components: {
        EditForm
    },
    props: {
        form: {
            type: Object,
            default(){
                return {};
            }
        },
        open: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        cancel(){
            this.$emit('update:open',false);
        },
        commit(){
            const params = {
                ...this.form,
                extra: JSON.stringify(this.form.extra)
            }
            saveApp(params).then(res => {
                this.$message.success('保存成功');
                this.cancel();
                this.$emit('getTableData');
                // console.log(res);  
                
                const routeData = this.$router.resolve({
                    name: "Detail",
                    query: {
                        id: res.id,
                    }
                });
                window.open(routeData.href, '_blank');
            })
        },
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog{
    margin-top: 50px !important;
}
.edit-page{
    .form{
        width: 90%;
        margin: 0 auto;
        margin-top: 10px;
    }

    .btn-group{
        width: 100%;
        display: flex;
        justify-content: center;
        /deep/ .el-button{
            margin: 0 60px;
        }
    }
}
</style>