<template>
    <div style="border: 1px solid #ccc;" class="wang-editor">
        <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="height:245px; overflow-y: hidden;"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
            @input="input"
        />
    </div>
</template>

<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default Vue.extend({
    components: { Editor, Toolbar },
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    watch: {
        value: {
            handler(val){
                if(val !== this.html){
                    this.html = val;
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            editor: null,
            html: '',
            toolbarConfig: {
                toolbarKeys: [
                    'headerSelect','|','bold','underline',
                    'fontSize','fontFamily','lineHeight','|','color','bgColor','|',
                    {
                        "key": "group-justify",
                        "title": "对齐",
                        "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z\"></path></svg>",
                        "menuKeys": [
                            "justifyLeft",
                            "justifyRight",
                            "justifyCenter",
                            "justifyJustify"
                        ]
                    },
                    'codeBlock','divider','|','undo','redo','|','fullScreen'
                ]
            },
            editorConfig: { placeholder: '请输入内容...' },
            mode: 'default', // or 'simple'
        }
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        input(val){
            this.$emit('input',val);
        }
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
})
</script>

<style lang="less" scoped>
.wang-editor{
    z-index: 99;
    /deep/ .w-e-text-container [data-slate-editor] p{
        margin: 5px 0;
    }
}
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>